<template>
  <div class="container w-sm-100 w-xs-100 px-5">
    <div class="row">
      <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
        <section id="remittance-form">
          <div data-v-if="showRemittanceForm">
            <h2 class="text-dark pb-4 text-center">This is the deposit page</h2>
            <h2 class="text-dark pb-4 text-center">How much would you like to transfer ?</h2>

            <!--TODO : use this component, fix the bugs so it displays correctly-->
            <!--<transcrypt-transfer-preparation/>-->
<!--            <div id="remittance-form-loading-container" class="vs-con-loading__container">-->
<!--              <div class="cryptoremit-receiving-currency relative">-->
<!--                <div id="receiving-fiat-container"-->
<!--                     class="money-input-form-group form-group form-group-lg mb-0">-->
<!--                  <label class="control-label" for="receiving-fiat-input">Recipient gets</label>-->
<!--                  <div class="money-input input-group input-group-lg">-->
<!--                    <input type="text"-->
<!--                           inputmode="decimal"-->
<!--                           placeholder=""-->
<!--                           id="receiving-fiat-input"-->
<!--                           autocomplete="off"-->
<!--                           class="form-control"-->
<!--                           v-model="receiveAmount"-->
<!--                           :class="[amountTooSmall || invalidFormat ? 'border-error' : '']"-->
<!--                    >-->
<!--                    <div class="input-group-btn">-->
<!--                      <div class="btn-group btn-block">-->
<!--                        <button class="btn btn-group btn-light"-->
<!--                                id="receiving-currency-button"-->
<!--                                @click.stop="toggleReceiveCurrencyOptions()"-->
<!--                                v-if="selectedReceiveCurrency">-->
<!--                          <country-flag-->
<!--                              :country="selectedReceiveCurrency.countryCode"></country-flag>-->
<!--                          <span class="m-1">{{ selectedReceiveCurrency.currencyCode }}</span>-->
<!--                          <chevron-down-icon size="1x"></chevron-down-icon>-->
<!--                        </button>-->
<!--                        <ul class="dropdown-currency-list"-->
<!--                            id="dropdown-menu-receiving-currency">-->
<!--                          <li class="dropdown-item-divider">-->
<!--                            <a class="currency-search">-->
<!--                              <div class="input-group">-->
<!--                                                            <span class="pt-2 pb-2 pr-2">-->
<!--                                                                <span>-->
<!--                                                                 <search-icon size="1x"-->
<!--                                                                              class="custom-class"></search-icon>-->
<!--                                                            </span>-->
<!--                                                            </span>-->
<!--                                <input type="text"-->
<!--                                       placeholder="Enter a currency / country"-->
<!--                                       style="border:0"-->
<!--                                       id="search-receive-currency-input"-->
<!--                                       class="w-100 pr-2"-->
<!--                                       v-model="searchReceivingCurrencyQuery"-->
<!--                                >-->
<!--                              </div>-->
<!--                            </a>-->
<!--                          </li>-->
<!--                          <div v-for="item in receiveCurrencyList" :key="item.currencyCode"-->
<!--                               :value="item.currencyCode">-->
<!--                            <li>-->
<!--                              <a class="currency-item"-->
<!--                                 @click="inputReceivingCurrency(item)">-->
<!--                                <country-flag :country="item.countryCode"-->
<!--                                              class="mr-1"></country-flag>-->
<!--                                {{ item.currencyCode }} <small class="ml-1">{{ item.currencyName }}</small>-->
<!--                              </a>-->
<!--                            </li>-->
<!--                          </div>-->


<!--                        </ul>-->

<!--                      </div>-->
<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-group-addon small mb-1">-->
<!--                  &lt;!&ndash;                                                                Exchange rate 1 BTC = {{formatFiatPrice(cryptoExchangeRate)}} {{sendCurrency}}&ndash;&gt;-->
<!--                  &lt;!&ndash;                                                                (indicative)&ndash;&gt;-->
<!--                  &lt;!&ndash;                                                                <span class="text-error" v-if="amountTooSmall"><small>Receive amount is too small.</small></span>&ndash;&gt;-->
<!--                </div>-->


<!--              </div>-->

<!--              <div class="vertical-steps">-->
<!--                <div class="step">-->
<!--                  <div class="plus-sign">-->
<!--                    +-->
<!--                  </div>-->
<!--                  <div class="content">-->
<!--&lt;!&ndash;                    Transfer fee = 0 {{ receiveCurrency }} <span&ndash;&gt;-->
<!--&lt;!&ndash;                      class="text-success">(0% fee promo until 25 May 2021)</span>&ndash;&gt;-->
<!--                    Transfer fee = {{formatFiatPrice(sendingTransferFee)}} {{ receiveCurrency }}-->
<!--&lt;!&ndash;                                                        (0.5% x&ndash;&gt;-->
<!--&lt;!&ndash;                                                        {{receiveAmount === "" ? 0.00 : formatFiatPrice(receiveAmount)}} {{receiveCurrency}})&ndash;&gt;-->

<!--                    &lt;!&ndash;                                   <strong>Total amount : {{ totalAmountToSend}}</strong>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="step">-->
<!--                  <div class="content">-->
<!--                    &lt;!&ndash;                                    Amount we will convert = {{formatFiatPrice(finalSendAmount)}} {{sendCurrency}}&ndash;&gt;-->
<!--                    Exchange rate BTC/{{ receiveCurrency }} = {{ formatFiatPrice(cryptoExchangeRate) }}-->
<!--                    (indicative)-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


<!--              <div class="relative mb-2">-->
<!--                <div id="crypto-amount-container"-->
<!--                     class="money-input-form-group form-group form-group-lg mb-0">-->
<!--                  <label class="control-label" for="crypto-amount-input">You send</label>-->
<!--                  <div class="money-input input-group input-group-lg">-->
<!--                    <input type="text"-->
<!--                           inputmode="decimal"-->
<!--                           placeholder=""-->
<!--                           id="crypto-amount-input"-->
<!--                           autocomplete="off"-->
<!--                           class="form-control"-->
<!--                           v-model="cryptoAmount"-->
<!--                           readonly>-->
<!--                    &lt;!&ndash;<p class="crypto-amount-in-input">(0.00000897 BTC)</p>&ndash;&gt;-->
<!--                    <div class="input-group-btn">-->
<!--                      <div class="btn-block">-->

<!--                        <button class="btn btn-group btn-light flex-row-align-center"-->
<!--                                id="crypto-amount-button"-->
<!--                                v-if="selectedReceiveCurrency"-->
<!--                                @click.stop="toggleCryptoCurrencyOptions()">-->
<!--                          <cryptoicon symbol="btc" size="27"/>-->
<!--                          <span class="ml-1 mr-1">BTC</span>-->
<!--                          <chevron-down-icon size="1x"></chevron-down-icon>-->
<!--                        </button>-->
<!--                        <ul class="dropdown-crypto-list" id="dropdown-menu-crypto-currency">-->
<!--                          &lt;!&ndash;                                                                                                <li class="dropdown-item-divider">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                    <a class="currency-search">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                        <div class="input-group">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            <span class="pt-2 pb-2 pr-2">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                <span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                 <search-icon size="1x"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                              class="custom-class"></search-icon>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            </span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            </span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            <input type="text" placeholder="Enter a currency / country"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                   style="border:0" id="search-crypto-currency-input"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                   class="w-100 pr-2"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                   v-model="searchReceivingCurrencyQuery"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            >&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                        </div>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                    </a>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                </li>&ndash;&gt;-->
<!--                          <div class="m-3">-->
<!--                            Other cryptocurrencies are coming soon.-->
<!--                          </div>-->
<!--                          &lt;!&ndash;                                                                                                <div v-for="item in receiveCurrencyList" :key="item.currencyCode"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                     :value="item.currencyCode">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                    <li>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                        <a class="currency-item" @click="selectReceiveCurrency(item)">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            <country-flag :country="item.countryCode"&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                                          class="mr-1"></country-flag>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                            {{item.currencyCode}} <small class="ml-1">{{item.currencyName}}</small>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                        </a>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                    </li>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                                                                                </div>&ndash;&gt;-->


<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;                            <div class="form-group-addon small">&ndash;&gt;-->
<!--                &lt;!&ndash;                                Exchange rate 1 {{sendCurrency}} = {{formatFiatPrice(fiatExchangeRate)}}&ndash;&gt;-->
<!--                &lt;!&ndash;                                {{receiveCurrency}}&ndash;&gt;-->
<!--                &lt;!&ndash;                            </div>&ndash;&gt;-->

<!--              </div>-->

<!--              <div class="flex-col-display">-->
<!--                &lt;!&ndash;                                <ul class="ml-2">&ndash;&gt;-->
<!--                &lt;!&ndash;                                    <li class="text-error" v-if="validationSendAmountError">&ndash;&gt;-->
<!--                &lt;!&ndash;                                        <small>Send amount should be bigger&ndash;&gt;-->
<!--                &lt;!&ndash;                                            than zero.</small>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    <li class="text-error" v-if="validationReceiveAmountError">&ndash;&gt;-->
<!--                &lt;!&ndash;                                        <small>Receive amount should be bigger than zero</small>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    <li class="text-error" v-if="validationCryptoAmountError">&ndash;&gt;-->
<!--                &lt;!&ndash;                                        <small> Crypto amount should be&ndash;&gt;-->
<!--                &lt;!&ndash;                                            bigger than zero.</small>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                                </ul>&ndash;&gt;-->
<!--                <small class="text-error" v-if="amountTooSmall">Receive amount is too small.</small>-->
<!--                <small class="text-error" v-else-if="invalidFormat">Enter only numeric characters for receive-->
<!--                  amount.</small>-->
<!--                <small class="text-error" v-else-if="exchangeRateError">Failed to get an exchange rate, please refresh the page and try again.</small>-->
<!--              </div>-->

<!--              <div class="pt-3">-->
<!--                <button class="btn btn-success btn-block mb-2" type="button"-->
<!--                        :disabled="amountTooSmall || invalidFormat"-->
<!--                        @click="submitRemittanceForm()">-->
<!--                  <span v-if="!loadingSpinner">Continue</span><span v-else>Loading...</span>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->

          </div>
          <!--                    <div v-else>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <h2 class="text-dark pb-4 text-center">Loading....</h2>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                        <br>-->
          <!--                    </div>-->
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);


export default {
  name: 'remittance-form',
  components: {

  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
      showRemittanceForm: false,

      sendAmount: 0,
      receiveAmount: 0,
      cryptoAmount: 0,
      finalSendAmount: 0,

      validationSendAmountError: false,
      validationReceiveAmountError: false,
      validationCryptoAmountError: false,

      sendCurrency: "",
      receiveCurrency: "",
      cryptoCurrency: "",
      transferFeeRate: 0.005,

      selectedSendCurrency: "",
      selectedReceiveCurrency: "",

      amountTooSmall: false,
      invalidFormat: false,


      // sendingCurrencies: ["USD", "GBP", "EUR", "AUD", "ZAR", "SGD"],
      // receivingCurrencies: ["USD", "GBP", "EUR", "AUD", "ZAR", "SGD"],

      cryptoExchangeRate: 0,
      fiatExchangeRate: 0,

      sendCurrencies: [
        {countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
      ],

      receiveCurrencies: [{countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
        //receiving currencies
        {countryCode: "ae", currencyCode: "AED", currencyName: "United Arab Emirates Dirham"},
        {countryCode: "bh", currencyCode: "BHD", currencyName: "Bahraini Dinar"},
        {countryCode: "bn", currencyCode: "BND", currencyName: "Bruneian Dollar"},
        {countryCode: "ca", currencyCode: "CAD", currencyName: "Canadian Dollar"},
        {countryCode: "ch", currencyCode: "CHF", currencyName: "Swiss Franc"},
        {countryCode: "cn", currencyCode: "CNY", currencyName: "Chinese Yuan"},
        {countryCode: "dk", currencyCode: "DKK", currencyName: "Danish Krone"},
        {countryCode: "eg", currencyCode: "EGP", currencyName: "Egyptian Pound"},
        {countryCode: "hk", currencyCode: "HKD", currencyName: "Hong Kong Dollar"},
        {countryCode: "hu", currencyCode: "HUF", currencyName: "Hungarian Forint"},
        {countryCode: "id", currencyCode: "IDR", currencyName: "Indonesian Rupiah"},
        {countryCode: "in", currencyCode: "INR", currencyName: "Indian Rupee"},
        {countryCode: "jp", currencyCode: "JPY", currencyName: "Japanese Yen"},
        {countryCode: "kr", currencyCode: "KRW", currencyName: "South Korean Won"},
        {countryCode: "kw", currencyCode: "KWD", currencyName: "Kuwaiti Dinar"},
        {countryCode: "lk", currencyCode: "LKR", currencyName: "Sri Lankan Rupee"},
        {countryCode: "mu", currencyCode: "MUR", currencyName: "Mauritian Rupee"},
        {countryCode: "mx", currencyCode: "MXN", currencyName: "Mexican Peso"},
        {countryCode: "my", currencyCode: "MYR", currencyName: "Malaysian Ringgit"},
        {countryCode: "no", currencyCode: "NOK", currencyName: "Norwegian Krone"},
        {countryCode: "nz", currencyCode: "NZD", currencyName: "New Zealand Dollar"},
        {countryCode: "ph", currencyCode: "PHP", currencyName: "Philippine Peso"},
        {countryCode: "pl", currencyCode: "PLN", currencyName: "Polish Zloty"},
        {countryCode: "qa", currencyCode: "QAR", currencyName: "Qatari Riyal"},
        {countryCode: "ru", currencyCode: "RUB", currencyName: "Russian Ruble"},
        {countryCode: "sa", currencyCode: "SAR", currencyName: "Saudi Arabian Riyal"},
        {countryCode: "se", currencyCode: "SEK", currencyName: "Swedish Krona"},
        {countryCode: "th", currencyCode: "THB", currencyName: "Thai Baht"},
        {countryCode: "tr", currencyCode: "TRY", currencyName: "Turkish Lira"},
        {countryCode: "tw", currencyCode: "TWD", currencyName: "Taiwan New Dollar"},
        {countryCode: "ng", currencyCode: "NGN", currencyName: "Nigerian Naira"},
        {countryCode: "br", currencyCode: "BRL", currencyName: "Brazilian Real"},
        {countryCode: "bd", currencyCode: "BDT", currencyName: "Bangladesh Taka"},
        {countryCode: "co", currencyCode: "COP", currencyName: "Colombian Peso"},
        {countryCode: "gh", currencyCode: "GHS", currencyName: "Ghanaian Cedi"},
        {countryCode: "ke", currencyCode: "KES", currencyName: "Kenyan Shilling"},
        {countryCode: "pe", currencyCode: "PEN", currencyName: "Peruvian Sol"},
        {countryCode: "tz", currencyCode: "TZS", currencyName: "Tanzanian Shilling"},
        {countryCode: "vn", currencyCode: "VND", currencyName: "Vietnamese Dong"},
        {countryCode: "zm", currencyCode: "ZMW", currencyName: "Zambian Kwacha"}],

      searchSendingCurrencyQuery: "",
      searchReceivingCurrencyQuery: "",

      debugConsole: false,

      loadingSpinner: false,

      sendingTransferFee: 0,
      remittanceContainer: null,

      exchangeRateError : false,


    }
  },
  watch: {
    $route() {

    },
    receiveAmount: debounce(function (newVal, oldVal) {
      if (Number(newVal) !== Number(oldVal)) {
        this.inputReceivingAmount();
      }
    }, 800),
  },
  computed: {
    ...mapGetters(["paymentDetailsGetter", "userDetailsGetter"]),

    // sendingCurrencyList() {
    //     let countries = this.sendCurrencies;
    //     let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1)
    //     return sortedCountries.filter((item) => {
    //         return (item.currencyName.toLowerCase().includes(this.searchSendingCurrencyQuery) || item.currencyCode.toLowerCase().includes(this.searchSendingCurrencyQuery))
    //     })
    // },

    receiveCurrencyList() {
      let countries = this.receiveCurrencies;
      let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1);
      return sortedCountries.filter((item) => {
        return (item.currencyName.toLowerCase().includes(this.searchReceivingCurrencyQuery) || item.currencyCode.toLowerCase().includes(this.searchReceivingCurrencyQuery))
      })
    },

    // transferFee() {
    //     let fee = this.sendAmount ? new BigNumber(this.sendAmount * this.transferFeeRate) : 0;
    //     return this.sendAmount > 1 ? fee.toFixed(2) : fee.toFixed(3);
    // },
    // convertedSendAmount() {
    //     let convertedSendAmount = this.sendAmount && this.transferFee ? new BigNumber(this.sendAmount - this.transferFee) : 0;
    //     return this.sendAmount > 1 ? convertedSendAmount.toFixed(2) : convertedSendAmount.toFixed(3);
    //
    // }
    // ,
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    if (this.debugConsole) console.debug("Remittance Form Mounted");
    var vm = this;

    let url = new URL(window.location);

    if (url.href.indexOf('sign_up=successful') > 0) {
      this.$toast.success('Sign Up Successful !', {dismissible: true, duration: 5000, position: "bottom"});
    }


    // Close the dropdown menu if the user clicks outside of it, except when click on search currency input
    let remittanceContainer = document.querySelector('.container')
    if (this.debugConsole) console.log("Remittance Container when mounted", remittanceContainer);
    remittanceContainer.addEventListener('click', function (event) {
      if (this.debugConsole) console.log("Click on the remittance container");
      if (this.debugConsole) console.log("Event target in remittance container", event.target);

      if (event.target.id !== "search-send-currency-input") {
        if (this.debugConsole) console.log("You are not in the search send currency input");
        document.getElementById("dropdown-menu-crypto-currency").classList.remove("show");
      }

      if (event.target.id !== "search-receive-currency-input") {
        if (this.debugConsole) console.log("You are not in the search receive currency input");
        document.getElementById("dropdown-menu-receiving-currency").classList.remove("show");
      }
    })

    this.remittanceContainer = remittanceContainer
    if (this.debugConsole) console.log("Remittance Container", this.remittanceContainer)

    //for stepper
    let target = "remittance-info";
    this.$store.commit('UPDATE_STEPPER', target);


    let remittanceInfo = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo;
    if (this.debugConsole) console.log('Remittance Info in transfer remittance form', remittanceInfo);


    // if (Object.keys(remittanceInfo).length > 0) {
    //     if (this.debugConsole) console.log("Remittance Info in store not empty");
    //
    //     this.sendCurrency = remittanceInfo.sendCurrency;
    //     this.receiveCurrency = remittanceInfo.receiveCurrency;
    //     this.cryptoCurrency = "BTC";
    //     this.sendAmount = remittanceInfo.sendAmount;
    //     this.receiveAmount = remittanceInfo.receiveAmount;
    //     this.cryptoAmount = remittanceInfo.cryptoAmount;
    //     this.fiatExchangeRate = remittanceInfo.fiatExchangeRate;
    //     this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate;
    //     this.finalSendAmount = remittanceInfo.finalSendAmount;
    //
    //     if (this.debugConsole) console.log("This.sendCurrency", this.sendCurrency);
    //     if (this.debugConsole) console.log("This.receiveCurrency", this.receiveCurrency);
    //
    //     this.selectedSendCurrency = this.sendCurrencies && this.sendCurrencies.find(item => item.currencyCode === this.sendCurrency);
    //     this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);
    //
    // }
    // else {
    //     if (this.debugConsole) console.log("Remittance Info in store empty");
    //     this.sendCurrency = "USD";
    //     this.receiveCurrency = "EUR";
    //     this.cryptoCurrency = "BTC";
    //
    //     let sendAmount = new BigNumber('1000.00')
    //     this.sendAmount = sendAmount.toFixed(2);
    //     if (this.debugConsole) console.log("Sending Amount (mounted)", this.sendAmount);
    //
    //     this.selectedSendCurrency = this.sendCurrencies && this.sendCurrencies.find(item => item.currencyCode === this.sendCurrency);
    //     this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);
    //
    //     //receive data from backend (exchange rate for the crypto & the fiat currency)
    //     let data = {};
    //     data.fromCurrency = this.sendCurrency;
    //     data.toCurrency = this.receiveCurrency;
    //
    //     this.getTranscryptExchangeRate(data).then(res => {
    //         if (this.debugConsole) console.log("Result fiat ", res.data);
    //
    //         let fiatExchangeRate = new BigNumber(res.data.exchange_rate);
    //         this.fiatExchangeRate = fiatExchangeRate.toFixed(2);
    //         if (this.debugConsole) console.log("Fiat Exchange Rate (mounted)", this.fiatExchangeRate);
    //
    //         let finalSendAmount = new BigNumber(this.sendAmount - this.transferFee)
    //         this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
    //         if (this.debugConsole) console.log("Final Send Amount (mounted)", this.finalSendAmount);
    //
    //         let receiveAmount = new BigNumber(this.finalSendAmount * this.fiatExchangeRate);
    //         this.receiveAmount = receiveAmount.toFixed(2);
    //         if (this.debugConsole) console.log("Receive Amount (mounted)", this.receiveAmount);
    //
    //     }, error => {
    //         if (this.debugConsole) console.error("Error in getting the fiat exc rate", error)
    //         this.showRemittanceForm = false;
    //     }).then(() => {
    //         let data = {};
    //         data.fromCurrency = this.cryptoCurrency;
    //         data.toCurrency = this.sendCurrency;
    //         this.getTranscryptExchangeRate(data).then(res => {
    //
    //             if (this.debugConsole) console.log("Result Crypto", res.data);
    //
    //             let cryptoExchangeRate = new BigNumber(res.data.exchange_rate);
    //             this.cryptoExchangeRate = cryptoExchangeRate.toFixed(2);
    //             if (this.debugConsole) console.log("Crytpo Exchange Rate (mounted)", this.cryptoExchangeRate);
    //
    //             let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
    //             this.cryptoAmount = cryptoAmount.toFixed(8);
    //             if (this.debugConsole) console.log("Crypto Amount (mounted)", this.cryptoAmount);
    //
    //             this.showRemittanceForm = this.cryptoExchangeRate && this.fiatExchangeRate;
    //
    //         }, error => {
    //             if (this.debugConsole) console.error("Error in getting the crypto exc rate", error)
    //             this.showRemittanceForm = false;
    //         })
    //     });
    //
    // }

    //thousand separator
    // const sendingFiatInput = document.getElementById('sending-fiat-input')
    // console.log("sendingFiatInput", sendingFiatInput);
    //
    // sendingFiatInput.addEventListener('blur',function(){
    //     console.log("sendingFiatInput is blurred");
    //     this.sendAmount = vm.thousandSeparator(vm.sendAmount)
    // })
    //
    // const receivingFiatInput = document.getElementById('receiving-fiat-input');
    // console.log("receivingFiatInput",receivingFiatInput)
    //
    // receivingFiatInput.addEventListener('blur', function(){
    //     console.log("receivingFiatInput is blurred");
    // })


    if (Object.keys(remittanceInfo).length > 0) {
      if (this.debugConsole) console.log("Remittance Info in store not empty");


      this.receiveCurrency = remittanceInfo.receiveCurrency;
      this.cryptoCurrency = "BTC";
      this.receiveAmount = remittanceInfo.receiveAmount;
      this.cryptoAmount = remittanceInfo.cryptoAmount;
      this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate;

      if (this.debugConsole) console.log("This.sendCurrency", this.sendCurrency);
      if (this.debugConsole) console.log("This.receiveCurrency", this.receiveCurrency);


      this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);
    } else {
      this.receiveCurrency = "USD";
      this.cryptoCurrency = "BTC";
      let receiveAmount = '100'
      this.receiveAmount = Number(receiveAmount);

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;

      this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);

      let data = {};
      data.fromCurrency = this.cryptoCurrency
      data.toCurrency = this.receiveCurrency

      this.getTranscryptExchangeRate(data).then(res => {
        if (this.debugConsole) console.log("Result in cryptoexchange rate", res.data);

        this.cryptoExchangeRate = res.data.exchange_rate;

        //TO DO change to new API to get the relevant data
        let payload = {}
        payload.receive_ccy = this.receiveCurrency;
        payload.receive_amt = this.receiveAmount;

        // console.log("Calculation payload in first inputting amount", payload);
        this.calculation(payload).then(res => {
          if (this.debugConsole) console.log("Calculation data in transfer remittance form", res.data);

          this.sendingTransferFee = res.data.fee;
          this.cryptoAmount = this.formatCryptoPrice(res.data.indicative_btc_amt);
          this.cryptoExchangeRate = res.data.crypto_xchg_rt;

          this.showRemittanceForm = true;
        }).catch(err => {
          if (this.debugConsole) console.log("Error in calculation in transfer remittance", err.response)

          if (err.response.data && err.response.data.message === "receive_amt_too_small") {
            this.amountTooSmall = true;
          }

          if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => el.message === 'invalid_receive_amt' && el.path === 'receive_amt')) {
            this.invalidFormat = true;

          }

          if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate" ) {
            this.exchangeRateError = true;
          }

        })
      }).catch(err => {
        if (this.debugConsole) console.log("Error in getting the crypto exchange rate", err.response);

      })
    }


  },
  beforeDestroy() {
    let remittanceContainer = document.querySelector('.container')
    if (this.debugConsole) console.log("Remittance Container in beforeDestroy", remittanceContainer);
    remittanceContainer.removeEventListener('click', function (event) {
      if (this.debugConsole) console.log("Click on the remittance container");
      if (this.debugConsole) console.log("Event target in remittance container", event.target);

      // if (event.target.id !== "search-send-currency-input") {
      //     if (this.debugConsole) console.log("You are not in the search send currency input");
      //     document.getElementById("dropdown-menu-sending-currency").classList.remove("show");
      // }

      if (event.target.id !== "search-receive-currency-input") {
        if (this.debugConsole) console.log("You are not in the search receive currency input");
        document.getElementById("dropdown-menu-receiving-currency").classList.remove("show");
      }
    })
  },
  methods: {
    ...
        mapActions(["getExchangeRate", "submitRemittanceDetails", "getTranscryptExchangeRate", "calculation"]),
    /* FOR NEW REMITTANCE FORM */

    inputReceivingAmount() {
      if (this.debugConsole) console.log("Receiving Amount", this.receiveAmount)

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;

      //TO DO change to new API to get the relevant data
      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = Number(this.receiveAmount);


      if (this.remittanceContainer) {
        this.$vs.loading({
          container: '#remittance-form-loading-container',
          scale: 0.8,
          color: 'success'
        })
      }

      // console.log("Calculation payload in receiving amount", payload);

      this.calculation(payload).then(res => {
        if (this.debugConsole) console.log("Calculation when input receiving amount", res.data);

        this.sendingTransferFee = res.data.fee;
        this.cryptoAmount = this.formatCryptoPrice(res.data.indicative_btc_amt)
        this.cryptoExchangeRate = res.data.crypto_xchg_rt;

        this.$vs.loading.close('#remittance-form-loading-container > .con-vs-loading')
      }).catch(err => {
        if (this.debugConsole) console.log("Error in calculatng data when input receiving amount", err.response);
        this.$vs.loading.close('#remittance-form-loading-container > .con-vs-loading')

        if (err.response.data && err.response.data.message === "receive_amt_too_small") {
          this.amountTooSmall = true;

        }

        if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => (el.message === 'invalid_receive_amt' || el.message === 'empty') && el.path === 'receive_amt')) {
          this.invalidFormat = true;
        }

        if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate" ) {
          this.exchangeRateError = true;
        }
      })
    },

    inputReceivingCurrency(item) {
      this.selectedReceiveCurrency = item;
      if (this.debugConsole) console.log("This selectedReceiveCurrency", this.selectedReceiveCurrency);
      this.receiveCurrency = this.selectedReceiveCurrency.currencyCode

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;

      let data = {};
      data.fromCurrency = this.cryptoCurrency;
      data.toCurrency = this.receiveCurrency;

      this.getTranscryptExchangeRate(data).then(res => {
        if (this.debugConsole) console.log("Result in cryptoexchange rate when select receiving curr", res.data);

        this.cryptoExchangeRate = res.data.exchange_rate;
        //TO DO change to new API to get the relevant data
        let payload = {}
        payload.receive_ccy = this.receiveCurrency;
        payload.receive_amt = Number(this.receiveAmount);

        this.$vs.loading({
          container: '#remittance-form-loading-container',
          scale: 0.8,
          color: 'success',
        })

        // console.log("Calculation payload in inputting currency", payload);
        //TO DO change to new API to get the relevant data
        this.calculation(payload).then(res => {
          if (this.debugConsole) console.log("Calculation when input receiving currency", res.data);

          this.sendingTransferFee = res.data.fee;
          this.cryptoAmount = this.formatCryptoPrice(res.data.indicative_btc_amt);
          this.cryptoExchangeRate = res.data.crypto_xchg_rt;

          this.$vs.loading.close('#remittance-form-loading-container > .con-vs-loading')
        }).catch(err => {
          if (this.debugConsole) console.log("Error in calculation in input receiving currency", err.response)
          this.$vs.loading.close('#remittance-form-loading-container > .con-vs-loading')

          if (err.response.data && (err.response.data.message === "receive_amt_too_small")) {
            this.amountTooSmall = true;

          }
          if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => (el.message === 'invalid_receive_amt' || el.message === 'empty') && el.path === 'receive_amt')) {
            this.invalidFormat = true;
          }

          if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate" ) {
            this.exchangeRateError = true;
          }
        })
      }).catch(err => {
        if (this.debugConsole) console.log("Error in getting exc rate in input receiving currency", err.response);
        this.$vs.loading.close('#remittance-form-loading-container > .con-vs-loading')

      })
    },
    toggleCryptoCurrencyOptions() {
      document.getElementById('dropdown-menu-crypto-currency').classList.toggle('show');
    },

    toggleReceiveCurrencyOptions() {
      document.getElementById("dropdown-menu-receiving-currency").classList.toggle('show');
      let classList = document.getElementById("dropdown-menu-receiving-currency").classList
      if (this.debugConsole) console.log('Class List', classList);

      document.getElementById("search-receive-currency-input").focus();
    },

    submitRemittanceForm() {

      //validation
      // if (!this.sendAmount || this.sendAmount && (Number(this.sendAmount) <= 0.00 || isNaN(this.sendAmount))) {
      //     this.validationSendAmountError = true;
      // } else {
      //     this.validationSendAmountError = false;
      // }

      if (!this.receiveAmount || this.receiveAmount && (Number(this.receiveAmount) <= 0.00 || isNaN(this.receiveAmount))) {
        this.validationReceiveAmountError = true;
      } else {
        this.validationReceiveAmountError = false;
      }

      if (!this.cryptoAmount || this.cryptoAmount && (Number(this.cryptoAmount) <= 0.00 || isNaN(this.cryptoAmount))) {
        this.validationCryptoAmountError = true;
      } else {
        this.validationCryptoAmountError = false;
      }

      if (this.validationCryptoAmountError || this.validationReceiveAmountError) {
        return;
      }

      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = this.receiveAmount;

      this.loadingSpinner = true;

      // console.log("Calculation payload in submit Remittance form", payload);

      this.submitRemittanceDetails(payload).then(result => {
        if (this.debugConsole) console.log("Result from Submit Remittance Form", result.data);

        let data = {}
        data.sendAmount = result.data && result.data.send_amt;
        data.sendCurrency = this.sendCurrency;
        data.cryptoAmount = result.data && result.data.indicative_btc_amt;
        data.receiveAmount = Number(this.receiveAmount);
        data.receiveCurrency = this.receiveCurrency;
        data.fiatExchangeRate = result.data && result.data.fiat_xchg_rt;
        data.cryptoExchangeRate = result.data && result.data.crypto_xchg_rt;
        data.transferFee = result.data && result.data.fee;
        data.txReference = result.data && result.data.tx_ref_num;
        data.finalSendAmount = result.data && (result.data.send_amt - result.data.fee);

        this.amountTooSmall = false;
        this.invalidFormat = false;

        //check if the sender details is filled in
        if (this.userDetailsGetter && this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderFirstAndMiddleName) {
          if (this.debugConsole) console.log("Go to recipient details")

          this.$store.commit('UPDATE_STEPPER', 'sender-info');
          this.$store.commit('UPDATE_STEPPER', 'recipient-info');
          this.$router.push('/transfer/recipient-details')

        } else {
          console.log("Go to sender details")
          this.$router.push('/transfer/sender-details');
          this.$store.commit('UPDATE_STEPPER', 'sender-info');
        }


        this.$store.commit("UPDATE_REMITTANCE_INFO", data);


      }).catch(error => {
        if (this.debugConsole) console.log('Error from Submit Remittance Form', error.response);
        if (error.response.data && error.response.data.message === "receive_amt_too_small") {
          this.amountTooSmall = true;

        }
        if (error.response.data && error.response.data.errors && error.response.data.errors.find(el => el.message === 'invalid_receive_amt' && el.path === 'receive_amt')) {
          this.invalidFormat = true;

        }
        this.loadingSpinner = false;
      })
    },


    /*DEPRECATED*/
    // toggleSendCurrencyOptions() {
    //     // event.stopPropagation();
    //     document.getElementById("dropdown-menu-sending-currency").classList.toggle('show');
    //     let classList = document.getElementById("dropdown-menu-sending-currency").classList
    //     if (this.debugConsole) console.log('Class List', classList);
    //
    //     document.getElementById("search-send-currency-input").focus();
    //
    // },
    //
    // selectSendCurrency(item) {
    //     if (this.debugConsole) console.log("Send Currency Item", item)
    //     this.selectedSendCurrency = item;
    //     if (this.debugConsole) console.log("selectedSendCurrency", this.selectedSendCurrency);
    //
    //     this.sendCurrency = this.selectedSendCurrency.currencyCode;
    //
    //     let cryptoData = {};
    //     cryptoData.fromCurrency = this.cryptoCurrency;
    //     cryptoData.toCurrency = this.sendCurrency;
    //
    //     if (this.debugConsole) console.log("cryptoData", cryptoData);
    //
    //     this.getTranscryptExchangeRate(cryptoData).then((res) => {
    //         let cryptoExchangeRate = new BigNumber(res.data.exchange_rate);
    //         this.cryptoExchangeRate = cryptoExchangeRate.toFixed(2);
    //         if (this.debugConsole) console.log("Crytpo Exchange Rate (change sending currency)", this.cryptoExchangeRate);
    //
    //         let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
    //         this.cryptoAmount = cryptoAmount.toFixed(8);
    //         if (this.debugConsole) console.log("Crypto Amount (change sending currency)", this.cryptoAmount);
    //     }, error => {
    //         if (this.debugConsole) console.log("Error in cryptoData Exchange Rate", error);
    //     })
    //
    //     let fiatData = {};
    //     fiatData.fromCurrency = this.sendCurrency;
    //     fiatData.toCurrency = this.receiveCurrency;
    //
    //     if (this.debugConsole) console.log("FiatData", fiatData);
    //
    //
    //     this.getTranscryptExchangeRate(fiatData).then((res) => {
    //         let fiatExchangeRate = new BigNumber(res.data.exchange_rate);
    //         this.fiatExchangeRate = fiatExchangeRate.toFixed(2);
    //         if (this.debugConsole) console.log("Fiat Exchange Rate (change sending currency)", this.fiatExchangeRate);
    //
    //         let finalSendAmount = new BigNumber(this.sendAmount - this.transferFee)
    //         this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
    //         if (this.debugConsole) console.log("Final send Amount (change sending Currency)", this.finalSendAmount);
    //
    //         let receiveAmount = new BigNumber(this.finalSendAmount * this.fiatExchangeRate);
    //         this.receiveAmount = receiveAmount.toFixed(2);
    //         if (this.debugConsole) console.log("Receive Amount (change sending currency)", this.receiveAmount);
    //     }, error => {
    //         if (this.debugConsole) console.log("Error in fiatData Exchange Rate", error);
    //     })
    // }
    // ,
    //
    // selectReceiveCurrency(item) {
    //     if (this.debugConsole) console.log("Receive currency Item", item);
    //     this.selectedReceiveCurrency = item;
    //     if (this.debugConsole) console.log("selectedReceivedCurrency", this.selectedReceiveCurrency);
    //
    //     this.receiveCurrency = this.selectedReceiveCurrency.currencyCode;
    //
    //     if (this.debugConsole) console.log("Change Receiving currency value");
    //     if (this.debugConsole) console.log("Receive Currency val", this.receiveCurrency);
    //
    //     let data = {};
    //     data.fromCurrency = this.sendCurrency;
    //     data.toCurrency = this.receiveCurrency;
    //
    //     this.getExchangeRate(data).then((res) => {
    //         let fiatExchangeRate = new BigNumber(res.data.exchange_rate);
    //         this.fiatExchangeRate = fiatExchangeRate.toFixed(2);
    //         if (this.debugConsole) console.log("Fiat Exchange Rate (change receiving currency)", this.fiatExchangeRate)
    //         if (this.debugConsole) console.log("Receive Amount (change receiving currency)", this.receiveAmount);
    //
    //         let finalSendAmount = new BigNumber(this.receiveAmount / this.fiatExchangeRate);
    //         this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
    //         if (this.debugConsole) console.log("Final Send Amount (change receiving currency", this.finalSendAmount);
    //
    //
    //         let sendAmount = new BigNumber(Number(this.finalSendAmount) / 0.995);
    //         this.sendAmount = sendAmount.toFixed(2);
    //         if (this.debugConsole) console.log("Sending Amount (change receiving currency) ", this.sendAmount);
    //
    //         if (this.debugConsole) console.log("cryptoexchangerate (change receiving currency)", this.cryptoExchangeRate);
    //
    //         let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
    //         this.cryptoAmount = cryptoAmount.toFixed(8);
    //         if (this.debugConsole) console.log('Crypto Amount (change receiving currency)', this.cryptoAmount);
    //     })
    // },
    //
    // changeSendingAmount() {
    //     if (this.debugConsole) console.log("Change Sending Amount called");
    //     if (this.debugConsole) console.log("Sending Amount", this.sendAmount);
    //
    //     let finalSendAmount = new BigNumber(this.sendAmount - this.transferFee);
    //     this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
    //     if (this.debugConsole) console.log("Final Send Amount when sending amount changed", this.finalSendAmount);
    //
    //     let receiveAmount = new BigNumber(this.finalSendAmount * this.fiatExchangeRate);
    //     this.receiveAmount = receiveAmount.toFixed(2);
    //     if (this.debugConsole) console.log("Receiving Amount when sending amount changed", this.receiveAmount);
    //
    //
    //     let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
    //     this.cryptoAmount = cryptoAmount.toFixed(8);
    //     if (this.debugConsole) console.log("Crypto amount when sending amount changed", this.cryptoAmount);
    //
    //     // console.log("Total amount to send when sending amount change", this.totalAmountToSend);
    // },
    //
    // changeReceivingAmount() {
    //     if (this.debugConsole) console.log('Change Receiving Amount called');
    //     if (this.debugConsole) console.log("Receiving Amount", this.receiveAmount);
    //
    //     let finalSendAmount = new BigNumber(this.receiveAmount / this.fiatExchangeRate);
    //     this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
    //     if (this.debugConsole) console.log('Final Send Amount when receiving Amount changed', this.finalSendAmount);
    //
    //     let sendAmount = new BigNumber(this.finalSendAmount / 0.995)
    //     this.sendAmount = sendAmount.toFixed(2);
    //     if (this.debugConsole) console.log('Sending Amount when receiving Amount changed', this.sendAmount);
    //
    //     let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
    //     this.cryptoAmount = cryptoAmount.toFixed(8);
    //     if (this.debugConsole) console.log("Crypto Amount when receiving amount changed", this.cryptoAmount);
    // },
  }
}

</script>
<style>


</style>